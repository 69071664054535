import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function Pregnancy() {
    return (
        <Layout>
            <Helmet>
                <title>Pregnancy related discomfort - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Pregnancy Related Pain and Discomfort</h1>
            </Title>
            <section>
                <Text>
                    <Underline>
                        <h2>A Whole Body Approach</h2>
                    </Underline>
                    <p>It’s quite normal to get some discomfort during and directly after a pregnancy, and whilst usually nothing serious, it’s natural to worry about what might be causing your symptoms.</p>
                    <p>Osteopaths spend four to five years training to understand the relationship between the body’s organs, joints and muscles. Given the unique stresses pregnancy puts upon the body, osteopaths are well suited to diagnosing and helping you with some of the problems you may experience when you’re expecting.</p>
                    <p>Your osteopath will discuss any concerns you have as part of your examination and will advise you on any further action that might be required.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>Common Pregnancy Complaints</h2>
                    </Underline>
                    <p>Many pregnant women experience back or pelvic pain. Common symptoms may include:</p>
                    <ul>
                        <li>Generalised muscular back pain</li>
                        <li>SPD (Symphasis Pubis Dysfunction) – pain or discomfort in the joint at the front of the pelvis</li>
                        <li>Sacro Iliac Joint pain – occurring in the lower back</li>
                        <li>Sciatica and leg pain associated with back pain</li>
                        <li>Rib and thoracic spinal problems leading to breathlessness and difficulty in deep breathing</li>
                        <li>Neck aches and headaches</li>
                    </ul>
                    <p>Many commonly used medications for these symptoms are often not recommended for a pregnant or breast feeding mother. Osteopathy offers an alternative approach to help you deal with these common conditions.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>Personal Treatment for a Natural Pregnancy</h2>
                    </Underline>
                    <p>Every patient’s problems are unique, and your treatment will be specific to your symptoms. Osteopaths use a wide range of hands-on techniques. These will vary depending on your age, fitness and diagnosis, but will focus on bodily tension, stretching muscles and mobilising your joints.</p>
                    <p>You may wish to discuss with your osteopath how many treatments are likely to be necessary, and how frequently you may feel you would benefit from osteopathic treatment. Some new mothers like to return for follow-up osteopathic treatment after being discharged by their obstetrician or midwife. A number of osteopaths are also trained to assess babies; check in advance if you would like to make an appointment for your child.</p>
                    <p>It’s quite normal to get some discomfort during and directly after a pregnancy, and whilst usually nothing serious, it’s natural to worry about what might be causing your symptoms. Your osteopath will discuss any concerns you have as part of your examination and will advise you on any further action that might be required.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>A Few Things for You to Remember</h2>
                    </Underline>
                    <ul>
                        <li>Take particular care when lifting or carrying things. This is especially important if you’re already a mother – try not to carry a small child on one hip for any length of time.</li>
                        <li>If standing for any length of time, vary your position frequently. Avoid sitting for prolonged periods if possible, and try getting up and moving around.</li>
                        <li>When sitting, adopt a posture that supports your back, thighs and feet. Use a cushion for support, and avoid crossing your legs.</li>
                        <li>When lying on your side, you may find a pillow under your bump and another between your knees offers support.</li>
                    </ul>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default Pregnancy